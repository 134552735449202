import { yupResolver } from '@hookform/resolvers/yup'
import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import isEmail from 'validator/lib/isEmail'
import * as yup from 'yup'

import ApolloClientServerCreator from '@graph/ApolloClientServerCreator'
import Button from '@ui/button/Button'
import GoogleAuthButton, { GoogleAuthActionTypes } from '@ui/common/GoogleAuthButton'
import Link from '@ui/common/Link'
import OrLine from '@ui/common/OrLine'
import PaperContentBlock from '@ui/common/PaperContentBlock'
import SubmissionError from '@ui/form/SubmissionError'
import TextField from '@ui/form/Text/TextField'
import { InputTypes } from '@ui/form/Text/TextInput'
import { ContentBlock } from '@ui/layout/ContentBlock'
import PublicPageLayout, { PublicPageLayoutProps } from '@ui/layout/PublicPageLayout'
import { Stack } from '@ui/layout/stack/Stack'
import { StackItem } from '@ui/layout/stack/StackItem'
import { Body } from '@ui/typography/Body'
import { Heading } from '@ui/typography/Heading'
import { NLPage } from '_app.page'
import { redirectTo } from 'app/server'
import { addGlobalPageProps } from 'app/utils'
import PageHead from 'page/PageHead'
import { dashboardPath, forgotPasswordPath } from 'routes/static'
import useEmailLoginUser from 'viewer/useEmailLoginUser'
import { isViewerLoggedIn } from 'viewer/utils'

import { LoginPageDocument, LoginPageQuery } from './query.generated'

type EmailLoginFormValues = {
  email: string
  password: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .test('isEmail', 'Must be a valid email', (value) => isEmail(value)),
  password: yup
    .string()
    .min(6, 'Must be at least 6 characters long')
    .required('Password is required'),
})

const Login: NLPage<LoginPageQuery, PublicPageLayoutProps> = () => {
  const router = useRouter()

  const useFormProps = useForm<EmailLoginFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    setError,
    formState: { errors, isValid, isValidating },
  } = useFormProps

  const onAuthenticated = useCallback(() => {
    const next = router.query?.next as string | null
    router.replace(next || dashboardPath)
  }, [router])

  const [emailLoginUser, { loading: isEmailLoginSubmitting }] = useEmailLoginUser({
    onSuccess: onAuthenticated,
    setError,
  })

  const onSubmit = ({ email, password }: EmailLoginFormValues) => {
    emailLoginUser(email, password)
  }

  const isSubmissionDisabled = isEmailLoginSubmitting || !isValid || isValidating

  return (
    <FormProvider {...useFormProps}>
      <PageHead
        title='Login | Nanny Lane'
        description='Log into Nanny Lane to start the search for your perfect nanny or family.'
      />
      <ContentBlock
        py={{ _: 0, tablet: 5 }}
        maxWidth={{ _: 'page.minimal.mobile', tablet: 'page.minimal.tablet' }}
      >
        <PaperContentBlock
          as='form'
          onSubmit={handleSubmit(onSubmit)}
          px={{ _: 4, tablet: 6 }}
          pt={{ _: 5, tablet: 6 }}
          pb={{ _: 8, tablet: 6 }}
          noValidate
        >
          <Stack gap='4'>
            <Stack gap='5'>
              <Heading as='h1' size='md' textAlign='left'>
                Login
              </Heading>

              <StackItem alignSelf='center'>
                <GoogleAuthButton
                  action={GoogleAuthActionTypes.LOGIN}
                  onAuthenticated={onAuthenticated}
                  disabled={isEmailLoginSubmitting}
                />
              </StackItem>
            </Stack>

            <ContentBlock py='2'>
              <OrLine />
            </ContentBlock>

            {errors?.root?.serverError && <SubmissionError error={errors.root.serverError} />}

            <TextField label='Email' name='email' type={InputTypes.EMAIL} />

            <TextField label='Password' name='password' type={InputTypes.PASSWORD} />

            <Button
              type='submit'
              variant='gradient'
              gradient='family'
              width={1}
              disabled={isSubmissionDisabled}
            >
              Login
            </Button>

            <ContentBlock>
              <Link href={forgotPasswordPath} variant='highlight'>
                Forgot password?
              </Link>
            </ContentBlock>

            <StackItem alignSelf='center'>
              <Stack gap='2' direction='horizontal'>
                <Body>Don&apos;t have an account?</Body>
                <Link href='/signup'>Get Started</Link>
              </Stack>
            </StackItem>
          </Stack>
        </PaperContentBlock>
      </ContentBlock>
    </FormProvider>
  )
}

Login.getLayout = function getLayout(page) {
  return <PublicPageLayout includes={{ navigation: true, footer: false }}>{page}</PublicPageLayout>
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const client = ApolloClientServerCreator.call(context)
  const { data } = await client.query({ query: LoginPageDocument })
  const isLoggedIn = isViewerLoggedIn(data.viewer)

  if (isLoggedIn) {
    const { next = dashboardPath } = context.query
    return redirectTo(next as string)
  }

  return addGlobalPageProps<LoginPageQuery>(client, context, {
    includeCountrySelectPanel: false,
    ...data,
  })
}

export default Login
